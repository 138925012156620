import React, { useState } from 'react'
import styled from 'styled-components';
import { ReactComponent as CheckBoxIcon } from '../Assets/CheckboxIcon.svg'

const CheckboxContainer = styled.label`
  display:flex;
  align-items:center;
  cursor: pointer;
`;

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const CheckboxCustom = styled.span`
  position: relative;
  display:block;
  width: 21px;
  height: 23px;
  background-color: #fff;
  border: 1px solid #DBDBDB;
  border-radius: 4px;
`;

const Check = styled.div`
  position:absolute;
  top:-1px;
  left:-2px;
`

const CheckboxLabel = styled.span`
  padding-left: 10px;
  font-size:15px;

  
  @media (max-width: 1440px) {
    font-size:13px;
  }

  @media (max-width: 899.98px) {
    font-size:11px;
  }

  @media (max-width: 599.98px) { 
    font-size:11px;
  }
`;

const CustomCheckbox = ({ name, value, setData, label }) => {

  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (e) => {
    setData(
      (prev) => ({
        ...prev,
        [name]: !isChecked
      })
    )
    setIsChecked(!isChecked);
  }

  return (
    <CheckboxContainer>
      <CheckboxInput type="checkbox" required name={name} value={value} onChange={handleChange} />
      <CheckboxCustom ><Check><CheckBoxIcon style={{ display: isChecked ? 'block' : 'none' }} /></Check></CheckboxCustom>
      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxContainer>
  )
}

export default CustomCheckbox