import React from 'react'
import styled from 'styled-components'

const Input = styled.input`
    padding-top:10px;
    padding-bottom:10px;
    text-indent: 10px;
    width:100%;
    max-width:360px;
    outline:none;
    border:1px solid #DBDBDB;
    border-radius:7px;

    @media (max-width: 1440px) {
      max-width:240px;
    }

    @media (max-width: 899.99px) {
      max-width:none;
    }
    
    @media (max-width: 599.99px) {
      max-width:none;
    }
`

const CustomInput = ({ setData, type, name, placeholder, minLength, maxLength,value }) => {

  const handleChange = (e) => {
    const Value = e.target.value;
    setData(
      (prev) => ({
        ...prev,
        [name]: Value
      })
    )

  }
  return (
    <Input type={type} name={name} value={value} required onChange={handleChange} minLength={minLength} maxLength={maxLength} placeholder={placeholder} />
  )
}

export default CustomInput