import React from 'react'
import { Box, Grid } from '@mui/material'
import { ReactComponent as Logo } from '../Assets/Logo.svg'
import NavLinks from './NavLinks'
import { useWindowScroll } from "@uidotdev/usehooks";
import useWindowDimensions from '../hooks/getWindowDimensions';
import HamburgerMenu from './HamburgerMenu';


const Navbar = () => {

    const [{ y }] = useWindowScroll();

    const ScrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    };


    const { width } = useWindowDimensions();

    return (
        <Box width={"100%"} backgroundColor={width > 899.99 ? (y >= 250 ? "#fff" : "transparent") : ("#fff")} position={'fixed'} py={2} top={0} left={0} zIndex={1000}>
            <Grid container paddingInline={width < 899.99 && "25px"} justifyContent={width < 899.99 ? "space-between" : 'center'} columnSpacing={10} alignItems={'center'}>
                <Grid item onClick={ScrollToTop}>
                    <Logo style={{cursor:"pointer"}}/>
                </Grid>
                <Grid item>
                    {width > 899.99 ? (
                        <Grid container columnSpacing={4}>
                            <Grid item>
                                <NavLinks link='Product' />
                            </Grid>
                            <Grid item>
                                <NavLinks link='Pricing' />
                            </Grid>
                            <Grid item>
                                <NavLinks link='Cases' />
                            </Grid>
                            <Grid item>
                                <NavLinks link='Blog' />
                            </Grid>
                            <Grid item>
                                <NavLinks link='More' />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={'auto'}>
                            <div>
                                <HamburgerMenu />
                            </div>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Box>
    )
}

export default Navbar