import React from 'react'
import { Box, Grid } from '@mui/material'
import { ReactComponent as Logo } from '../Assets/Logo.svg'
import NavLinks from './NavLinks'
import useWindowDimensions from '../hooks/getWindowDimensions';
import HamburgerMenu from './HamburgerMenu';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const SignUp = styled.button`
    font-size:15px;
    font-weight:700;
    line-height:21px;
    padding:10px;
    border-radius:150px;
    border:1px solid #00A79D;
    color:#00A79D;
    background-color:#fff;
`

const AplikoNavbar = () => {

    const { width } = useWindowDimensions();

    return (
        <Box width={"100%"} backgroundColor={"#fff"} position={'fixed'} py={2} top={0} left={0} zIndex={1000}>
            {width > 1100 ? (
                <Grid container paddingInline={"200px"} justifyContent={'space-between'} >
                    <Grid item>
                        <Grid container alignItems={'center'} columnSpacing={6}>
                            <Grid item>
                                <Link to='/'>
                                    <Logo />
                                </Link>

                            </Grid>
                            <Grid item>
                                <Grid container columnSpacing={4}>
                                    <Grid item>
                                        <NavLinks link='Product' />
                                    </Grid>
                                    <Grid item>
                                        <NavLinks link='Pricing' />
                                    </Grid>
                                    <Grid item>
                                        <NavLinks link='Cases' />
                                    </Grid>
                                    <Grid item>
                                        <NavLinks link='Blog' />
                                    </Grid>
                                    <Grid item>
                                        <NavLinks link='More' />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container columnSpacing={2} alignItems={'center'}>
                            <Grid item>
                                <NavLinks link='Log In' />
                            </Grid>
                            <Grid item>
                                <SignUp>Sign Up ➝</SignUp>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Grid container paddingInline={"25px"} justifyContent={'space-between'} >
                    <Link to='/'>
                        <Logo />
                    </Link>
                    <Grid item xs={'auto'}>
                        <div>
                            <HamburgerMenu second={true} />
                        </div>
                    </Grid>
                </Grid>
            )}

        </Box>
    )
}

export default AplikoNavbar