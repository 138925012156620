import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import Desc from '../Components/Desc'
import SectionBreaker from '../Components/SectionBreaker'
import Container from '../Components/Container'
import { ReactComponent as Apliko } from '../Assets/Apliko.svg'
import Button from '../Components/Button'
import useWindowDimensions from '../hooks/getWindowDimensions'
import { Link } from 'react-router-dom'

const Title = styled.p`
    font-size:60px;
    font-weight:500;
    line-height:71px;
    width:100%;
    max-width:500px;
    margin-bottom:10px;

    @media (max-width: 899.98px) {
        font-size:40px;
        line-height:41px;
        max-width:300px;
    }

    @media (max-width: 599.98px) { 
        font-size:40px;
        line-height:41px;
        max-width:300px;
    }
`

const GreenText = styled.span`
    font-size:60px;
    font-weight:500;
    line-height:71px;
    color:#00A79D;

    @media (max-width: 899.98px) {
        font-size:40px;
        line-height:41px;
    }

    @media (max-width: 599.98px) { 
        font-size:40px;
        line-height:41px;
    }
`

const SectionApliko = () => {

    const { width } = useWindowDimensions();
    return (
        <SectionBreaker>
            <Container>
                <Grid container justifyContent={'center'} alignItems={'center'} paddingInline={width < 899.99 && "25px"}>
                    <Grid item xs={12} md={7}>
                        <Grid item xs={12} >
                            <Title>Suksesi yt fillon
                                me <GreenText>insuplus</GreenText>!</Title>
                        </Grid>
                        <Grid item xs={12}>
                            <Desc desc={'Insuplus është një kompani telekomunikimi me fokus në tregun zvicrran. Me një angazhim të thellë për inovacion dhe cilësi, Insuplus ofron zgjidhje të avancuara të telekomunikacionit që përshtaten me nevojat e një game të gjerë klientësh, që përfshijnë individë, biznese të vogla dhe korporata të mëdha.'} />
                        </Grid>
                        <Grid item xs={12}>
                            <Link to='/apliko'>
                                <Button button={'Apliko'} color={'#fff'}/>
                            </Link>

                        </Grid>
                    </Grid>
                    {width > 899.99 ? (
                        <Grid item xs={12} md={5}>
                            <Apliko style={{ width: "100%", height: "auto" }} />
                        </Grid>
                    ) : (
                        <Grid item xs={12} md={5} textAlign={'center'}>
                            <Apliko style={{ width: "100%", maxWidth: "300px", height: "auto" }} />
                        </Grid>
                    )}
                </Grid>
            </Container>
        </SectionBreaker>

    )
}

export default SectionApliko