import React from 'react'
import SectionBreaker from '../Components/SectionBreaker'
import Container from '../Components/Container'
import { ReactComponent as Kosova } from '../Assets/Kosova.svg'
import { Box } from '@mui/material'
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import ResponsiveLocationCards from '../Components/ResponsiveLocationCard'

const SectionLokacioniResponsive = () => {
    return (
        <SectionBreaker>
            <Container>
                <Box paddingInline={3}>
                    <Swiper
                        pagination={true}
                        modules={[Pagination]}
                        centeredSlides={true}
                        breakpoints={{
                            0: {
                                slidesPerView: 1.2,
                                spaceBetween: 10,
                            },
                            600: {
                                slidesPerView: 2.5,
                                spaceBetween: 10,
                            },
                            800: {
                                slidesPerView: 2.5,
                                spaceBetween: 10,
                            },
                            1200: {
                                slidesPerView: 2.5,
                                spaceBetween: 20,
                            }
                        }}
                        className="responsiveSwiper">
                        <SwiperSlide>
                            <ResponsiveLocationCards city={'Prishtine'} desc={"Rruga 4 llullat, Bajram Kelmendi. Ju mirepresim"} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <ResponsiveLocationCards city={'Prishtine'} desc={"Rruga 4 llullat, Bajram Kelmendi. Ju mirepresim"} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <ResponsiveLocationCards city={'Prishtine'} desc={"Rruga 4 llullat, Bajram Kelmendi. Ju mirepresim"} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <ResponsiveLocationCards city={'Prishtine'} desc={"Rruga 4 llullat, Bajram Kelmendi. Ju mirepresim"} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <ResponsiveLocationCards city={'Prishtine'} desc={"Rruga 4 llullat, Bajram Kelmendi. Ju mirepresim"} />
                        </SwiperSlide>
                    </Swiper>
                </Box>
                <div style={{paddingTop:"50px", textAlign:"center"}}>
                    <Kosova style={{ width: "100%", height: "auto", maxWidth: "280px", margin: "auto" }} />
                </div>
            </Container>
        </SectionBreaker>
    )
}

export default SectionLokacioniResponsive