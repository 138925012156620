import React from 'react'
import SectionBreaker from './SectionBreaker'
import { Container, Grid } from '@mui/material'
import FooterLinks from './FooterLinks'
import styled from 'styled-components'
import LocationPhoneNumbers from './LocationPhoneNumbers'
import useWindowDimensions from '../hooks/getWindowDimensions'

const HelloText = styled.p`
    color:#fff;
    font-size:21.48px;
    font-weight:700;
    line-height:27.5px;
    margin-bottom:15px;
`

const CopyRight = styled.p`
    color:#fff;
    font-size:12px;
    font-weight:400;
    line-height:21px;
`
const Footer = () => {

    const { width } = useWindowDimensions();

    return (
        <SectionBreaker>
            <div style={{ background: "#00A79D" }}>
                <Container>
                    <Grid container py={3} justifyContent={'center'} paddingInline={width < 899.99 && "25px"} >
                        <Grid item xs={12} md={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <FooterLinks title={'Rreth nesh'} link1={'Kush jemi ne'} link2={'Pozitat e hapura'} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FooterLinks title={'Ndihmë'} link1={'Kontakti'} link2={'Politkat e privatësisë'} />
                                </Grid>
                                
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container flexDirection={'column'}>
                                <Grid item xs={12}>
                                    <HelloText>Lokacionet</HelloText>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container rowSpacing={2} >
                                        <Grid item xs={12}>
                                            <Grid container columnSpacing={5} rowSpacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <LocationPhoneNumbers city={'Prishtinë'} phonenumber={'+1 (337) 385 5102'} />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <LocationPhoneNumbers city={'Gjilan'} phonenumber={'+1 (337) 385 5102'} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container columnSpacing={5} rowSpacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <LocationPhoneNumbers city={'Gjakovë'} phonenumber={'+1 (337) 385 5102'} />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <LocationPhoneNumbers city={'Ferizaj'} phonenumber={'+1 (337) 385 5102'} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <LocationPhoneNumbers city={'Vushtrri'} phonenumber={'+1 (337) 385 5102'} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={'center'}>
                        <CopyRight>Copyright © 2024 Insuplus</CopyRight>
                    </Grid>
                </Container>
            </div>
        </SectionBreaker>
    )
}

export default Footer