import { Grid } from '@mui/material'
import React from 'react'
import SectionBreaker from '../Components/SectionBreaker'
import Container from '../Components/Container'
import { ReactComponent as Icons } from '../Assets/CallingforJobIcons.svg'
import styled from 'styled-components'
import Desc from '../Components/Desc'
import Button from '../Components/Button'
import useWindowDimensions from '../hooks/getWindowDimensions'

const Wrapper = styled.div`
    border-radius:20px;
    diplay:flex;
    justify-content:center;
    padding:30px;
    background-color:#00A79D;
`

const WrapperImage = styled.div`
    display:flex;
    justify-content:center;
`

const Title = styled.div`
    font-size:40px;
    font-weight:700;
    line-height:52px;
    color:#fff;
    width:100%;
    max-width:300px;
    text-align:left !important;

    @media (max-width: 1440px) {
        font-size:43px;
        line-height:50px;
    }

    @media (max-width: 899.98px) {
        font-size:33px;
        line-height:40px;
    }

    @media (max-width: 599.98px) { 
        font-size:33px;
        line-height:40px;
    }
`

const DescImage = styled.span`
    display:block;
    color:#fff;
    font-size:18.87px;
    width:100%;
    max-width:400px;
`
const SectionCallingforJob = () => {

    const { width } = useWindowDimensions();

    return (
        <SectionBreaker>
            <Container>
                <Grid container alignItems={'center'} rowGap={10} paddingInline={width < 899.99 && "25px"}>
                    <Grid item xs={12} md={6} textAlign={'center'}>
                        {
                            width > 899.99 ? (
                                <Icons style={{ width: "100%", height: "auto", maxWidth: "450px" }} />
                            ) : (
                                <Icons style={{ width: "100%", maxWidth: "300px", height: "auto" }} />
                            )
                        }
                    </Grid>
                    <Grid item xs={12} md={6} textAlign={'left'}>
                        {
                            width > 899.99 ? (
                                <WrapperImage style={{ backgroundImage: `url(${require('../Assets/Thought.png')})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', padding: width > 899.99 ? "75px" : "25px" }}>

                                    <Grid container justifyContent={'center'} alignItems={'center'} rowGap={3} columnGap={0}>
                                        <Grid item xs={12}>
                                            <Title>Mundësia po të thërret</Title>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DescImage>Na kontakto tani për çdo informatë rreth kompanisë tonë dhe dikush nga ekipi ynë do të kthehet tek ju.</DescImage>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button button={'Apliko'} style={{ maxWidth: "300px", backgroundColor: "#fff", borderRadius: "52px" }} color={"#00A79D"} />
                                        </Grid>
                                    </Grid>

                                </WrapperImage>
                            ) : (
                                <Wrapper>
                                    <Grid container flexDirection={'column'} justifyContent={'center'}>
                                        <Grid item xs={12}>
                                            <Title>Mundesia po te</Title>
                                            <Title>therret</Title>
                                        </Grid>
                                        <Grid item xs>
                                            <Desc style={{ color: "#fff", textAlign: "left", maxWidth: width < 899.99 ? "270px" : "400px", fontSize: width < 899 && "11.5px" }} desc={'Na kontakto tani për çdo informatë rreth kompanisë tonë dhe dikush nga ekipi ynë do të kthehet tek ju'} />
                                        </Grid>
                                        <Grid item xs>
                                            <Button style={{ color: "#00A79D", backgroundColor: "#fff", maxWidth: "none" }} button={'Apliko'} color={'#00A79D'} />
                                        </Grid>
                                    </Grid>
                                </Wrapper>
                            )}
                    </Grid>
                </Grid>
            </Container>
        </SectionBreaker >
    )
}

export default SectionCallingforJob