import { Box, Grid } from '@mui/material'
import React from 'react'
import Container from '../Components/Container'
import SectionBreaker from '../Components/SectionBreaker'
import LocationCards from '../Components/LocationCards'
import { ReactComponent as Kosova } from '../Assets/Kosova.svg'
import useWindowDimensions from '../hooks/getWindowDimensions'

const SectionLokacioni = () => {

    const { width } = useWindowDimensions();
    return (
        <SectionBreaker>
            <Container>
                <Grid container justifyContent={'center'} rowSpacing={8} position={'relative'}>
                    <Grid item >
                        <Grid container columnSpacing={30}>
                            <Grid item >
                                <LocationCards city={'Prishtinë'} desc={'Rruga 4 llullat, Bajram Kelmendi. Ju mirepresim'} />
                            </Grid>
                            <Grid item >
                                <LocationCards city={'Gjakovë'} desc={'Rruga 4 llullat, Bajram Kelmendi. Ju mirepresim'} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item >
                        <Grid container columnSpacing={55}>
                            <Grid item >
                                <LocationCards city={'Vushtrri'} desc={'Rruga 4 llullat, Bajram Kelmendi. Ju mirepresim'} />
                            </Grid>
                            <Grid item>
                                <LocationCards city={'Ferizaj'} desc={'Rruga 4 llullat, Bajram Kelmendi. Ju mirepresim'} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item >
                        <Grid container columnSpacing={25}>
                            <Grid item >
                                <LocationCards city={'Gjilan'} desc={'Rruga 4 llullat, Bajram Kelmendi. Ju mirepresim'} />
                            </Grid>
                            <Grid item >
                                <LocationCards city={'Peje'} desc={'Së shpejti..'} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box position={'absolute'} top={0} left={340}>
                        {width > 899.99 ? (
                            <Kosova style={{width:"100%", height:"auto"}}/>

                        ) : (
                            <Kosova style={{width:"100%", height:"auto", maxWidth:"500px"}}/>
                        )}
                    </Box>
                </Grid>
            </Container>
        </SectionBreaker >
    )
}

export default SectionLokacioni