import { Grid } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Attachment } from '../Assets/Attachment.svg';
import { ReactComponent as UploadedFile } from '../Assets/UploadedFile.svg';

const CVButton = styled.label`
    display:block;
    width: 100%;
    border:${(props) => (props.fileUploaded ? "1px solid #00A79D" : "1px solid #EBEFF3")};
    padding-left:5px;
    padding-top:5px;
    padding-bottom:5px;
    border-radius:5px;
    color:#00A79D;
    font-weight:700;
    font-size:14px;
    line-height:18.31px;

    @media (max-width: 1440px) {
        font-size:12px;
      }
    
      @media (max-width: 899.98px) {
        font-size:10px;
      }
    
      @media (max-width: 599.98px) { 
        font-size:10px;
      }
`;

const CVtext = styled.span`
    display: block;
    width: 100%;
    max-width: 270px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.7px;
    color: #94A2AB;

    @media (max-width: 1440px) {
        font-size:10px;
      }
    
      @media (max-width: 899.98px) {
        font-size:9px;
      }
    
      @media (max-width: 599.98px) { 
        font-size:9px;
      }
`;

const UploadCVButton = ({ setData, name, value }) => {

    const [fileName, setfileName] = useState('Ngarko Dokumentin')
    const [sizeMb, setsizeMb] = useState('Madhesia maksimale e lejuar e dokumentit eshte 25MB. Llojet e lejuara: PDF, .doc, .docx')
    const [fileUploaded, setfileUploaded] = useState(false)

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

        if (file && allowedTypes.includes(file.type) && file.size > 0) { // Check if file exists, type is allowed, and size is greater than 0
            const sizeInMB = file.size / (1024 * 1024); // Calculate file size in MB
            // console.log('File size (MB):', sizeInMB);
            if (sizeInMB <= 30) { // Check if file size is within the limit
                setfileName(file.name);
                setfileUploaded(true);
                const formData = new FormData();
                formData.append(name, file);
                setData(prevData => ({
                    ...prevData,
                    cv: formData
                }));
                const sizeInMB = file.size / (1024 * 1024);
                setsizeMb(`${sizeInMB.toFixed(2)} MB`);
                return;
            }
        }

        // If file is invalid or exceeds size limit
        setfileName('Ngarko Dokumentin');
        setsizeMb('Madhesia maksimale e lejuar e dokumentit eshte 25MB. Llojet e lejuara: PDF, .doc, .docx');
        console.log('Invalid file');
    };

    return (
        <Grid container rowGap={0.5} columnGap={5} alignItems={'center'}>
            <Grid item xs={12} md={5} xl={3.5}>
                <CVButton fileUploaded htmlFor="file-upload">
                    <Grid container alignItems={'center'} columnGap={1} >
                        <Grid item>
                            {
                                fileUploaded ? (
                                    <UploadedFile />
                                ) : (
                                    <Attachment />
                                )
                            }
                        </Grid>
                        <Grid item>
                            {fileName}
                        </Grid>
                    </Grid>
                    <input
                        type='file'
                        id="file-upload"
                        name={name}
                        accept=".pdf,.doc,.docx"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                </CVButton>
            </Grid>
            <Grid item xs md xl>
                <CVtext>{sizeMb} {sizeMb ? 'MB' : ''} </CVtext>
            </Grid>
        </Grid>
    );
};

export default UploadCVButton;
