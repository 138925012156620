import { Grid } from '@mui/material'
import React from 'react'
import { ReactComponent as LocationIcon } from '../Assets/LocationIcon.svg'
import styled from 'styled-components'

const Wrapper = styled(Grid)`
    display:flex;
    border-radius:8px;
    width:380px;
    padding:20px;
    align-items:center;
    box-shadow: 0px 56px 100px 0px rgba(32, 32, 32, 0.07);
    transition: 0.3s ease, background-color 0.3s ease;
    border:1px solid #fff;

    &:hover {
        background-color: rgba(0, 167, 157, 0.2);    
        border:1px solid #00A79D;
    }
`

const City = styled.span`
    font-size:24.8px;
    font-weight:700;
    line-height:27.5px;
`
const Desc = styled.span`
    display:block;
    font-size:16.31px;
    font-weight:400;
    line-height:25.2px;
    width:100%;
    max-width:250px;
`

const LocationCards = ({ city, desc }) => {
    return (
        <Wrapper>
            <Grid item>
                <LocationIcon />
            </Grid>
            <Grid item pl={2}>
                <Grid container flexDirection={'column'} rowSpacing={1}>
                    <Grid item>
                        <City>{city}</City>
                    </Grid>
                    <Grid item>
                        <Desc>{desc}</Desc>
                    </Grid>
                </Grid>
            </Grid>
        </Wrapper>
    )
}

export default LocationCards