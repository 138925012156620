import React from 'react'
import SectionBreaker from '../Components/SectionBreaker'
import Container from '../Components/Container'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import Desc from '../Components/Desc'
import Button from '../Components/Button'
import JobsCard from '../Components/JobsCard'
import { ReactComponent as Callagent } from '../Assets/CallagentIcon.svg'
import { ReactComponent as Qualityagent } from '../Assets/QualityagentIcon.svg'
import { ReactComponent as Confirmationagent } from '../Assets/ConfirmationagentIcon.svg'
import useWindowDimensions from '../hooks/getWindowDimensions'

const T = styled.p`
    color:#fff;
    font-size:32px;
    line-height:57.82px;
    font-weight:700;

    @media (max-width: 899.98px) {
        font-size:20px;
        line-height:30px;
    }

    @media (max-width: 599.98px) { 
        font-size:20px;
        line-height:30px;
    }
`

const Title = styled.span`
    color:#fff;
    font-size:47.47px;
    line-height:60px;
    font-weight:400;

    @media (max-width: 899.98px) {
        font-size:28px;
        line-height:30px;
    }

    @media (max-width: 599.98px) { 
        font-size:28px;
        line-height:30px;
    }
`

const GreenText = styled.span`
    color:#49BFB3;
    font-size:47.47px;
    line-height:60px;
    font-weight:700;

    @media (max-width: 899.98px) {
        font-size:28px;
        line-height:30px;
    }

    @media (max-width: 599.98px) { 
        font-size:28px;
        line-height:30px;
    }
`

const Wrapper = styled.div`
    transition: padding-left 0.3s ease;

    &:hover {
        padding-left: 150px;
    }

    
    @media (max-width: 899.98px) {
        &:hover {
            padding-left: initial;
        }
    }

    @media (max-width: 599.98px) { 
        &:hover {
            padding-left: initial;
        }
    }
`

const SectionJobs = () => {

    const { width } = useWindowDimensions();
    return (
        <SectionBreaker>
            <div style={{ backgroundImage: `url(${require('../Assets/Background.jpg')})`, backgroundSize: 'cover', padding: width > 899.99 ? "100px" : "25px" }}>
                <Container>
                    <Grid container justifyContent={'space-between'} alignItecenterms={'center'} rowSpacing={5}>
                        <Grid item xs={12} md={6}>
                            <Grid container flexDirection={'column'}>
                                <Grid item>
                                    <T>Vende te lira pune</T>
                                </Grid>
                                <Grid item>
                                    <Title>Dëshiron të <GreenText>sfidosh veten </GreenText> dhe të <GreenText>avancosh</GreenText> në karrierë?</Title>
                                </Grid>
                                <Grid item>
                                    <Desc style={{ color: "#fff" }} desc={'Dërgo aplikimin tënd dhe bëhu pjesë e kompanisë tonë.'} />
                                </Grid>
                                <Grid item>
                                    <Button button={'Shiko të gjitha pozitat e hapura'} color={'#fff'} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} lg={'auto'}>
                            <Grid container flexDirection={'column'} rowSpacing={5} columnSpacing={0} width={'fit-content'}>
                                <Grid item xs={'auto'}>
                                    <Wrapper>
                                        <JobsCard svg={<Callagent style={{ width: "100%", height: "auto", maxWidth: width < 899.99 && "50px" }} />} job={'Call Agent'} desc={'Apliko Tani'} />
                                    </Wrapper>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <Wrapper>
                                        <JobsCard svg={<Qualityagent style={{ width: "100%", height: "auto", maxWidth: width < 899.99 && "50px" }} />} job={'Quality Agent'} desc={'Apliko Tani'} />
                                    </Wrapper>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <Wrapper>
                                        <JobsCard svg={<Confirmationagent style={{ width: "100%", height: "auto", maxWidth: width < 899.99 && "50px" }} />} job={'Confirmation Agent'} desc={'Apliko Tani'} />
                                    </Wrapper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </SectionBreaker>
    )
}

export default SectionJobs