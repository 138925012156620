import React from 'react';
import styled from 'styled-components';

const Select = styled.select`
    width: 100%;
    padding: 10px;
    border: 1px solid #DBDBDB;
    border-radius: 6px;
    outline: none;
`;


const CustomSelect = ({options ,name, setData}) => {
    
    const handleChange = (e) => {
        const selectedValue = e.target.value;
        setData(
            (prev) => ({
                ...prev,
                [name]: selectedValue
            })
        )
    }

    return (
        <div>
            <Select required  onChange={handleChange}>
                {options.map((option, index) => (
                    <option key={index} value={option.value} name={name}>
                        {option.label}
                    </option>
                ))}
            </Select>
        </div>
    );
};

export default CustomSelect;
