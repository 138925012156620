import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const B = styled.button`
    background-color:#00A79D;
    width:100%;
    padding-top:15px;
    padding-bottom:15px;
    max-width:340px;
    color:#fff;
    border-radius:12px;
    outline:none;
    border:none;
    font-size:18px;
    line-height:18px;
    font-weight:700;
    cursor:pointer;

    @media (max-width: 899.98px) {
        font-size:14px;
        line-height:15px;
        max-width:250px;
    }

    @media (max-width: 599.98px) { 
        font-size:14px;
        line-height:15px;
        max-width:250px;
    }
`

const Button = ({ button, style, color }) => {
    return (
        <B style={{ ...style }}><Link to='/apliko' style={{color:color, textDecoration:"none"}}>{button}</Link></B>
    )
}

export default Button