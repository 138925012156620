import React from 'react'
import styled from 'styled-components'

const NavLink = styled.p`
    font-size:16.34px;
    font-weight:400;
    line-height:17px;
`


const NavLinks = ({link}) => {
  return (
    <NavLink>{link}</NavLink>
  )
}

export default NavLinks