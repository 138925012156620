import React from 'react'
import SectionBreaker from '../Components/SectionBreaker'
import Container from '../Components/Container'
import { Grid } from '@mui/material'
import { ReactComponent as Insuplus } from '../Assets/InsuplusBuilding.svg'
import styled from 'styled-components'
import Desc from '../Components/Desc'
import useWindowDimensions from '../hooks/getWindowDimensions'


const T = styled.p`
    color:#3F4652;
    font-size:32px;
    line-height:57.82px;
    font-weight:700;

    @media (max-width: 899.98px) {
        font-size:20px;
        line-height:30px;
    }

    @media (max-width: 599.98px) { 
        font-size:20px;
        line-height:30px;
    }
`

const Title = styled.span`
    color:#3F4652;
    font-size:47.47px;
    line-height:60px;
    font-weight:400;

    @media (max-width: 899.98px) {
        font-size:28px;
        line-height:30px;
    }

    @media (max-width: 599.98px) { 
        font-size:28px;
        line-height:30px;
    }
`

const GreenText = styled.span`
    color:#49BFB3;
    font-size:47.47px;
    line-height:60px;
    font-weight:700;

    @media (max-width: 899.98px) {
        font-size:28px;
        line-height:30px;
    }

    @media (max-width: 599.98px) { 
        font-size:28px;
        line-height:30px;
    }
`
const SectionHistoria = () => {


    const { width } = useWindowDimensions();

    return (
        <SectionBreaker>
            <Container>
                <Grid container justifyContent={'center'} alignItems={'center'} columnSpacing={5} paddingInline={width < 899.99 && "25px"}>
                    <Grid item xs={12} md={6}>
                        <Grid container>
                            <Grid item>
                                <T>Rreth nesh</T>
                            </Grid>
                            <Grid item>
                                <Title>Historia e <GreenText>Insuplus</GreenText> dhe misioni ynë në <GreenText>shërbim të klientit</GreenText></Title>
                            </Grid>
                            <Grid item>
                                <Desc desc={'Që nga themelimi ynë, kemi qëndruar të angazhuar për të ofruar shërbime të cilësisë së lartë që i përgjigjen nevojave të ndryshme të klientëve tanë. Në zemër të misionit tonë qëndron inovacioni, integriteti dhe ndërtimi i marrëdhënieve të qëndrueshme. Me një ekip të dedikuar profesionistësh dhe përdorimin e teknologjive më të fundit, ne sigurojmë që çdo klient të përjetojë një lidhje të shpejtë, të sigurt dhe të qëndrueshme. '} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}  textAlign={'center'}>
                        {width > 899.99 ? (
                            <Insuplus style={{width:"100%", height:"auto"}}/>
                        ) : (
                            <Insuplus style={{width:"100%",maxWidth:"300px", height:"auto"}}/>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </SectionBreaker>
    )
}

export default SectionHistoria