import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as PhoneIcon } from '../Assets/FooterPhoneIcon.svg'
const Phone = styled.span`
    font-size:15.61px;
    color:#fff;
    font-weight:600;
    line-height:25.3px;
`

const City = styled.span`
    font-size:15.61px;
    color:#fff;
    font-weight:400;
    line-height:25.3px;
`
const LocationPhoneNumbers = ({ city, phonenumber }) => {
    return (
        <Grid container columnSpacing={3}>
            <Grid item>
                <Grid container alignItems={'center'} columnSpacing={1}>
                    <Grid item>
                        <PhoneIcon />
                    </Grid>
                    <Grid item>
                        <City>{city}</City>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item>
                <Phone>{phonenumber}</Phone>
            </Grid>
        </Grid>
    )
}

export default LocationPhoneNumbers