import React from 'react'
import SectionBreaker from '../Components/SectionBreaker'
import Container from '../Components/Container'
import { Grid } from '@mui/material'
import { ReactComponent as PagaFikse } from '../Assets/PagaFikse.svg'
import { ReactComponent as Kontrat } from '../Assets/KontratAfatgjat.svg'
import { ReactComponent as Ambient } from '../Assets/Ambient.svg'
import useWindowDimensions from '../hooks/getWindowDimensions'
const SectionBenefitet = () => {


    const { width } = useWindowDimensions();
    return (
        <SectionBreaker>
            <Container>
                <Grid container justifyContent={'center'} columnSpacing={10} rowSpacing={4}>
                    <Grid item>
                        {
                            width > 899.99 ? (
                                <PagaFikse style={{ width: "100%", height: "auto" }} />
                            ) : (
                                <PagaFikse style={{ width: "100%", maxWidth: "200px", height: "auto" }} />
                            )
                        }

                    </Grid>
                    <Grid item>
                        {
                            width > 899.99 ? (
                                <Kontrat style={{ width: "100%", height: "auto" }} />
                            ) : (
                                <Kontrat style={{ width: "100%", maxWidth: "200px", height: "auto" }} />
                            )
                        }

                    </Grid>
                    <Grid item>
                        {
                            width > 899.99 ? (
                                <Ambient style={{ width: "100%", height: "auto" }} />
                            ) : (
                                <Ambient style={{ width: "100%", maxWidth: "200px", height: "auto" }} />
                            )
                        }
                    </Grid>
                </Grid>
            </Container>
        </SectionBreaker>
    )
}

export default SectionBenefitet