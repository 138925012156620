import { Grid } from '@mui/material';
import React from 'react'
import styled from 'styled-components';
import { ReactComponent as AccordIcon } from '../Assets/AccordIcon.svg'
import { ReactComponent as Bullet } from '../Assets/ListBullet.svg'

const Wrapper = styled(Grid)`
    width:100%;
    background-color:#00A79D;
    padding:15px 10px;
    border-radius:8px;
    color:#fff;
    justify-content:space-between;
    align-items:center;
`

const Text = styled.span`
    display:block;
    font-size:14px;
    font-weight:600;
    line-height:14px;
`

const ControlledAccordion = ({ text, onChange, expanded, text1, text2, text3, text4, text5 }) => {

    const handleChange = () => {
        onChange(!expanded);
    };

    return (
        <Wrapper container rowGap={3} onClick={handleChange}>
            <Grid item>
                <Text>{text}</Text>
            </Grid>
            <Grid item>
                <AccordIcon />
            </Grid>
            {
                expanded && (
                    <Grid item xs={12}>
                        <Grid container rowGap={2}>
                            <Grid item xs={12}>
                                <Grid container alignItems={'center'}>
                                    <Grid item xs={0.7}>
                                        <Bullet />
                                    </Grid>
                                    <Grid item xs>
                                        {text1}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems={'center'}>
                                    <Grid item xs={0.7}>
                                        <Bullet />
                                    </Grid>
                                    <Grid item xs>
                                        {text2}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems={'center'}>
                                    <Grid item xs={0.7}>
                                        <Bullet />
                                    </Grid>
                                    <Grid item xs>
                                        {text3}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems={'center'}>
                                    <Grid item xs={0.7}>
                                        <Bullet />
                                    </Grid>
                                    <Grid item xs>
                                        {text4}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems={'center'}>
                                    <Grid item xs={0.7}>
                                        <Bullet />
                                    </Grid>
                                    <Grid item xs>
                                        {text5}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
        </Wrapper>
    )
}

export default ControlledAccordion