import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const T = styled.p`
    color:#fff;
    font-size:18px;
    font-weight:700;
    line-height:27.5px;
`

const L = styled.span`
    color:#fff;
    font-size:14px;
    font-weight:400;
    line-height:25.2px;
`

const FooterLinks = ({ title, link1, link2, link3, svg1, svg2 }) => {
    return (
        <Grid container flexDirection={'column'}>
            <Grid item>
                <T>{title}</T>
            </Grid>
            <Grid item>
                <Grid container flexDirection={'column'} rowSpacing={1}>
                    <Grid item>
                        <L>
                            {link1}
                        </L>
                    </Grid>
                    <Grid item>
                        <L>
                            {link2}
                        </L>
                    </Grid>
                    <Grid item>
                        <L>
                            {link3}
                        </L>
                    </Grid>
                    <Grid item>
                        <Grid container columnSpacing={2} >
                            <Grid item>{svg1}</Grid>
                            <Grid item>{svg2}</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    )
}

export default FooterLinks